const aboutData = {
    PersonPosition: "psycholog-psychoterapeuta",
    AnnaLuderaName: "Anna Ludera",
    AnnaLuderaDescription: `Ukończyłam studia psychologiczne (specjalizacja kliniczna) na Uniwersytecie Marii Curie-Skłodowskiej w Lublinie oraz 4-letnią Szkołę Psychoterapii Psychodynamicznej w Krakowskim Centrum Psychodynamicznym. 
    
    Jestem certyfikowanym psychoterapeutą Polskiego Towarzystwa Psychologicznego (certyfikat nr 254). 
    Doświadczenie zawodowe zdobywałam pracując w Domu Pomocy Społecznej, Domu Dziecka oraz stale w Poradni Zdrowia Psychicznego i Poradni Zdrowia Psychicznego dla Dzieci i Młodzieży. Prowadzę psychoterapię indywidualną młodzieży i osób dorosłych. 
    
    Pracę psychoterapeutyczną prowadzę pod stałą superwizją u certyfikowanych superwizorów. 
    
    Jestem członkiem Polskiego Towarzystwa Psychologicznego, Polskiego Towarzystwa Psychoterapii Psychodynamicznej oraz Podkarpackiego Stowarzyszenia Psychoterapeutów.`,
    JadwigaJochymStachyraName: "Jadwiga Jochym\u00a0-\u00a0Stachyra",
    JadwigaJochymStachyraDescription: `Psycholog ,  certyfikowany psychoterapeuta Polskiego Towarzystwa Psychologicznego (certyfikat nr 352). 
    
    Ukończyłam psychologię na Katolickim Uniwersytecie Lubelskim i czteroletnią Szkołę Psychoterapii w Krakowskim Centrum Psychodynamicznym.  
    Od 2005 pracuję w Poradni Zdrowia Psychicznego dla dorosłych i Poradni Zdrowia Psychicznego dla Dzieci i Młodzieży w ZOZ nr 2 w Rzeszowie. 
    
    Od wielu lat prowadzę praktykę prywatną. Pracę z pacjentami poddaję superwizji u certyfikowanych superwizorów Polskiego Towarzystwa Psychologicznego , Polskiego Towarzystwa Psychiatrycznego i Polskiego Towarzystwa Psychoterapii Psychoanalitycznej. 
    
    Prowadzę psychoterapię dzieci, młodzieży, dorosłych i  psychoterapię par.`,
    AnnaDziobakName: "Anna Dziobak",
    AnnaDziobakDescription: `Jestem psychologiem, psychoterapeutą. 
    Posiadam dyplom psychoterapeuty Krakowskiego Centrum Psychodynamicznego, uzyskany w ramach 4-letniej Szkoły Psychoterapii Psychodynamicznej, rekomendowanej przez Polskie Towarzystwo Psychologiczne. 
    
    Ukończyłam 2-letnie podyplomowe studia z zakresu seksuologii klinicznej na UAM w Poznaniu. Przygotowuję się do uzyskania certyfikatu psychoterapeuty Polskiego Towarzystwa Psychologicznego. 
    
    Doświadczenie kliniczne zdobywałam w Oddziale Psychiatrycznym Klinicznego Szpitala Wojewódzkiego Nr 1 w Rzeszowie oraz w Poradni Zdrowia Psychicznego. 
    
    Prowadzę psychoterapię indywidualną młodzieży i osób dorosłych. Zajmuję się także poradnictwem psychologicznym. 
    Praktykę prowadzę pod stałym nadzorem superwizyjnym certyfikowanych superwizorów PTP oraz PTS.
    `
}

export default aboutData;