import React, { useEffect, useState } from 'react';
import image from '../assets/images/kid.jpg';
import smallImage from '../assets/images/chairs.jpg';
import ImageSection from './Sections/ImageSection';
import { Col, Row } from 'react-bootstrap';
import ListWithNumbers from './Sections/ListWithNumber';
import { useRef } from 'react';

const WorkMethodologyPage = () => {
    const ref = useRef(null);
    const [heightOfBox, setHeightOfBox] = useState(1000);
    useEffect(() => {
        setHeightOfBox(ref?.current?.clientHeight);
    }, []);

    return (
        <div>
            <div id="work-methodology">
                <ImageSection
                    height="30vh"
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    title="Metoda Pracy"
                />
                <div className="box" ref={ref}>
                    <div>
                        <Row className="offer_text_container ">
                            <Col md={6} style={{ animation: "simplyOpacity 1s forwards" }}>
                                {` Psychoterapia to forma leczenia trudności natury psychicznej. Prowadzi do poznania i zrozumienia siebie, do wypracowania sposobów radzenia sobie z problemami, do lepszego i bardziej satysfakcjonującego życia.

Psychoterapia psychodynamiczna ma swoje korzenie w psychoanalizie. Działanie lecznicze przypisuje się odkrywaniu przez pacjenta jego nieświadomych myśli i emocji, a także analizowaniu nieświadomych mechanizmów kierujących jego zachowaniem.

Pierwszym krokiem jest uświadomienie problemu przez pacjenta. Nie powoduje to zniknięcia zgłaszanych objawów, ale pozwala osobie na wgląd w siebie, lepsze zrozumienie swojego funkcjonowania, mechanizmów kierujących jego zachowaniem i przyjrzenie się swoim problemom z pełną świadomością. 

Psychoterapia nie polega na udzielaniu porad, chodzi o to, aby osoba sama o sobie decydowała.

Istotna dla procesu terapii jest relacja terapeutyczna, jaka zachodzi między pacjentem a psychoterapeutą. Ważne jest, aby pacjent poczuł się bezpiecznie, co pozwala na ujawnianie często skrywanych problemów, przełamania jakiegoś „tabu”. Sesje psychoterapii psychodynamicznej odbywają się w określonej częstotliwości (minimum 1x w tygodniu) i godzinie, obowiązują jasne zasady kontaktu ustalane miedzy terapeutą a pacjentem. `}
                            </Col>
                            <Col xs={12} md={6}><div className="image" style={{ backgroundImage: `url(${smallImage})`, backgroundPosition: "50% 100%", opacity: 0, animation: "simplyOpacity 1s 0.3s forwards", minHeight: "300px", backgroundSize: "cover", filter: "sepia(0.3) contrast(0.9) saturate(0.8)"  }} /></Col>
                        </Row>
                    </div>

                </div>
                <ImageSection
                    height={`calc(70vh - 70px - ${heightOfBox}px)`}
                    image={image}
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    imagePosition= "0% 30%"
                />
            </div>
        </div>
    );
};

export default WorkMethodologyPage;
