/* eslint-disable import/no-named-as-default */
import { Route, Switch, useLocation } from 'react-router-dom';

import AboutPage from './AboutPage.jsx';
import OfferPage from './OfferPage.jsx';
import HomePage from './HomePage.jsx';
import NotFoundPage from './NotFoundPage.jsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import WorkMethodologyPage from './WorkMethodologyPage.jsx';
import ContactPage from './ContactPage.jsx';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const App = () => {
  // document.body.classList.add('js-loading');
  const location = useLocation();
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);

  }, [location]);

  return (
    <div>
      <div>
        <Header sticky/>
      </div>
      <div className="app_container">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/offer" component={OfferPage} />
          <Route path="/workmethodology" component={WorkMethodologyPage} />
          <Route path="/contact" component={ContactPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
