import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row, Button } from "react-bootstrap";
import image2 from "../assets/images/kid.jpg";
import ImageSection from "./Sections/ImageSection.jsx";
import { Link } from "react-router-dom";
import TherapyIcon from "../assets/images/icons/TherapyIcon";


const HomePage = () => {
  const onArrowClick = () => {
    window.scrollTo(window.scrollX, 700);
  }
  //noBreakSpace = `\u00A0`;

  return (
    <>
      <div id="home-page">
        <ImageSection
          height="calc(100vh - 50px)"
          // image={image2}
          // imagePosition="30% 0%"
          title={`Ośrodek Diagnozy Psychologicznej\ni Psychoterapii`}
          titleVerticalAlign="center"
          description={`Świadczymy kompleksową pomoc psychologiczną dzieciom, młodzieży i\u00A0osobom dorosłym.`}
          withGradient
          gradientPosition="bottom"
        />

        <div className="box">
          <button className="button_arrow-down" onClick={onArrowClick}><i className="fa fa-arrow-down" /></button>
          <Row className="row_with_padding">
            <Col sm={6} xs={12} md={3}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={150}
                duration={0.5}
                animateOnce
              >
                <div className="main_card">
                  <i className="fa"><TherapyIcon /></i>
                  <div>
                    {`Oferujemy profesjonalną psychoterapię prowadzoną w\u00A0nurcie psychodynamicznym.`}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col sm={6} xs={12} md={3}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={300}
                duration={0.5}
                animateOnce
              >
                <div className="main_card">
                  <i className="fa fa-users" />
                  <div>
                    {`Kadrę ośrodka stanowią wykwalifikowani i\u00A0doświadczeni psychologowie-psychoterapeuci.`}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col sm={6} xs={12} md={3}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={450}
                duration={0.5}
                animateOnce
              >
                <div className="main_card">
                  <i className="fa fa-file-alt" />
                  <div>
                    {`Każdy z psychologów ukończył kompletne szkolenie w\u00A0zakresie psychoterapii, a\u00A0swoją pracę poddaje systematycznej superwizji.`}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col sm={6} xs={12} md={3}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={450}
                duration={0.5}
                animateOnce>
                <div className="main_card">
                  <i className="fa fa-hands-with-heart" />

                  <div>
                    {`Istotną kwestię stanowi zapewnienie każdej osobie poczucia bezpieczeństwa i\u00A0zaufania w\u00A0relacji terapeutycznej.`}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row>
            <Col className="center" style={{ marginTop: "15px" }}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={150}
                duration={0.5}
                animateOnce>
                <Link to="/about">
                  <Button variant="secondary">Więcej</Button>
                </Link>
              </ScrollAnimation>

            </Col>
          </Row>
        </div>
        <div>
          <ImageSection
            height="90vh"
            image={image2}
            imagePosition="0% 30%"
            withGradient
            gradientPosition="top"
            gradientHeight="30%"
            align="right"
          >
            <div className="image-section__body" style={{ mariginTop: "auto", marginBottom: "auto" }}>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={400}
                duration={0.5}
                animateOnce
              >
                <h2
                  className="title"
                  style={{ marginLeft: "auto", textAlign: "right" }}
                >
                  <span style={{ animationDelay: "0.5s" }}>
                    {"Masz pytania?"}
                  </span>
                </h2>
              </ScrollAnimation>
              <ScrollAnimation
                animatePreScroll={false}
                animateIn="fade-in-from-top"
                offset={400}
                duration={0.5}
                animateOnce
              >
                <h2
                  className="description fade-in "
                  style={{
                    marginLeft: "auto",
                    animationDelay: "1.5s",
                    textAlign: "right",
                  }}
                >

                  <span>{`Jeżeli coś Cię martwi, masz problemy, z\u00A0którymi sobie nie radzisz: 
                    pomożemy Ci zrozumieć Twoje trudności \u00A0będziemy towarzyszyć Ci w znalezieniu rozwiązania. 
                    Zapraszamy do`} </span><Link className="link" to="/contact">kontaktu.</Link>

                </h2>
              </ScrollAnimation>
            </div>
            {/* <ScrollAnimation
              animatePreScroll={false}
              animateIn="fadeIn"
              duration={0.5}
              offset={250}
              animateOnce
            >
              <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                  <div style={{ marginTop: "300px" }} className="card"></div>
                </Col>
              </Row>
            </ScrollAnimation> */}
          </ImageSection>
        </div>
      </div>
    </>
  );
};

export default HomePage;
