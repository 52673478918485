import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

const propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.object,
    gradientPosition: PropTypes.string,
    withGradient: PropTypes.bool,
    gradientHeight: PropTypes.string,
    align: PropTypes.string,
    imagePosition: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.string,
        description: PropTypes.string,
        imagePosition: PropTypes.string
    }))
};
const defaultProps = {
    gradientPosition: "top",
    withGradient: false,
    gradientHeight: "100%",
    align: "left",
    items: [],
};

const ImageCardsSection = ({
    items,
    height,
}) => {

    const [selectedItem, setSelectedItem] = useState(null);

    const onClickItem = (index) => {
        if (selectedItem == null)
            setSelectedItem(index);
        else setSelectedItem(null);
    }
    const getClassNameForItemByIndex = (itemIndex) => {
        if (selectedItem == itemIndex)
            return "active";
        else if (selectedItem != null && selectedItem != itemIndex) return "hidden"
        return "";
    }

    console.log(selectedItem);
    return (
        <div
            className="imageCards_section"
            style={{ height: height, overflow: "hidden" }}
        >
            <div className="imageCards_section-body">
                {items.map((item, index) => (
                    <div className={`imageCards-item ${getClassNameForItemByIndex(index)}`} style={{ animationDelay: index * 0.25 + "s" }} >
                        <div className="imageCards-item__image_section">
                            <div className="image"
                                style={{
                                    backgroundImage: `url(${item.image})`,
                                    backgroundPosition: item.imagePosition
                                }}
                                onClick={() => { onClickItem(index) }} />
                            {selectedItem != null ? (<div className="imageCards_section-back">Powrót</div>) : ""}
                            <div className="imageCards_section-title">
                                <h1 className="imageCards_section-title__name">{item.name}</h1>
                                <h2 className="imageCards_section-title__position">{item.position}</h2>
                            </div>
                        </div>
                        <div className="imageCards-item__data_section">
                            <div>
                                <h2 className="imageCards_section-position">{item.position}</h2>
                                <h1 className="imageCards_section-name">{item.name}</h1>
                                <span className="imageCards_section-description">{item.description}</span>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            {/* <div className="image" style={{ backgroundImage: `url(${image})`, backgroundPosition: imagePosition }} /> */}
        </div >
    );
};

ImageCardsSection.propTypes = propTypes;
ImageCardsSection.defaultProps = defaultProps;

export default ImageCardsSection;
