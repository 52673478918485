import * as React from "react";

function ChildrenIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.35 272.35" {...props} fill="#fff">
            <g id="Warstwa_2" data-name="Warstwa 2">
                <g id="teens">
                    <path class="cls-1" d="M136.17,0A136.18,136.18,0,1,0,272.35,136.17,136.18,136.18,0,0,0,136.17,0Zm9.38,72.69c.3-1,3.41-3.41,10.08-3.85s9.83,2.57,10.2,2A19.06,19.06,0,0,1,181.92,62h0A19,19,0,0,1,198,70.82c.38.59,3.55-2.41,10.21-2s9.78,2.89,10.07,3.85-.76,10.18-6.82,13.48c-1.31.67-7.26-6.93-8.5-6.92a2.24,2.24,0,0,0-2,1.81,19.06,19.06,0,0,1-19.07,19.06h0a19.06,19.06,0,0,1-19.06-19.06,2.26,2.26,0,0,0-2-1.81c-1.24,0-7.18,7.59-8.49,6.93C146.31,82.87,145.22,73.75,145.55,72.69ZM88.1,62A19.07,19.07,0,1,1,69,81.07,19.06,19.06,0,0,1,88.1,62Zm44.82,82.4c-2.55,2.31-6.21,4.7-10,1.09-3.47-3.25-15.13-16-15.13-16v73.67c-1,8.49-15.79,10.9-16.5-.43v-40.3A2.52,2.52,0,0,0,88.86,160H88a2.52,2.52,0,0,0-2.46,2.38s0,36.18,0,40.3C84.82,214,70,211.61,69,203.12V129.45s-11.66,12.79-15.13,16c-3.76,3.61-7.41,1.22-10-1.09a6.1,6.1,0,0,1-.87-8.23c1.78-2.17,3.47-3.9,13.65-16.68,6.68-7.23,11.91-11.17,15.16-13.22a13.24,13.24,0,0,1,8.32-2.41H96.65a13.28,13.28,0,0,1,8.33,2.41c3.24,2,8.47,6,15.15,13.22C130.32,132.27,132,134,133.78,136.17A6.1,6.1,0,0,1,132.92,144.4Zm93.49,0c-2.55,2.31-6.2,4.7-10,1.09-3.47-3.25-15.13-16-15.13-16v4.34s22.17,34.5,17,39.65c-1.54,1.53-8.26,2.56-17,3.13v26.55c-1,8.49-15.79,10.9-16.51-.43V177.1c-1.92,0-3.86,0-5.78,0v25.61c-.71,11.33-15.54,8.92-16.5.43V176.46c-8.88-.6-15.63-1.62-17-3-4.63-4.63,17-39.65,17-39.65v-4.34s-11.67,12.79-15.13,16c-3.76,3.61-7.42,1.22-10-1.09a6.11,6.11,0,0,1-.87-8.23c1.79-2.17,3.47-3.9,13.65-16.68,6.68-7.23,11.92-11.17,15.16-13.22a13.27,13.27,0,0,1,8.32-2.41h16.46a13.26,13.26,0,0,1,8.32,2.41c3.25,2,8.48,6,15.16,13.22,10.18,12.78,11.87,14.51,13.65,16.68A6.11,6.11,0,0,1,226.41,144.4Z" />
                </g>
            </g>
        </svg>
    );
}
export default ChildrenIcon;