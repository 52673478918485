import React, { useEffect, useState } from "react";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const propTypes = { sticky: PropTypes.bool };
const defaultProps = { sticky: false };

function Footer() {
  return (
    <footer id="footer">
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#111"
          d="M0,160L26.7,165.3C53.3,171,107,181,160,176C213.3,171,267,149,320,133.3C373.3,117,427,107,480,117.3C533.3,128,587,160,640,186.7C693.3,213,747,235,800,240C853.3,245,907,235,960,229.3C1013.3,224,1067,224,1120,202.7C1173.3,181,1227,139,1280,128C1333.3,117,1387,139,1413,149.3L1440,160L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
        ></path>
      </svg> */}
      <div className="footer-body">
      <span>sensum.rzeszow.pl Al. Rejtana 10, 35-310 Rzeszów</span>
      <span>created by Hubert Pudło</span>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default memo(Footer);
