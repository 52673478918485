import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  titleVerticalAlign: PropTypes.string,
  description: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.object,
  gradientPosition: PropTypes.string,
  withGradient: PropTypes.bool,
  gradientHeight: PropTypes.string,
  align: PropTypes.string,
  imagePosition: PropTypes.string,
  animated: PropTypes.bool,
};
const defaultProps = {
  gradientPosition: "top",
  withGradient: false,
  gradientHeight: "100%",
  align: "left",
  titleVerticalAlign: "bottom",
  animated: true,
};

const ImageSection = ({
  image,
  title,
  titleVerticalAlign,
  description,
  height,
  withGradient,
  gradientPosition,
  children,
  gradientHeight,
  align,
  imagePosition,
  animated,
}) => {
  return (
    <div
      className="image_section"
      style={{ height: height, overflow: "hidden", transition: `${animated ? "all 0.3s" : "none"}` }}
    >
      <div
        style={{ height: gradientHeight }}
        className={`image_gradient ${withGradient ? `image_gradient-${gradientPosition}` : ""
          } `}
      />
      <div className="image" style={{ backgroundImage: `url(${image})`, backgroundPosition: imagePosition }} />
      {title || description ? (
        <div className="image-section__body" style={titleVerticalAlign == "center" ? { marginTop: "auto", marginBottom: "auto" } : {}}>
          <h2 className="title">
            <span style={{}}>{title}</span>
          </h2>
          <h2
            className="description fade-in "
            style={align == "right" ? { marginLeft: "auto", animationDelay: "1.0s", textAlign: "right" } : { animationDelay: "1.0s" }}
          >
            {description}
          </h2>
        </div>
      ) : (
        ""
      )
      }

      {children}
    </div >
  );
};

ImageSection.propTypes = propTypes;
ImageSection.defaultProps = defaultProps;

export default ImageSection;
