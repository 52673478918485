import React, { useLayoutEffect, useState } from 'react';
import image from '../assets/images/kid.jpg';
import ImageSection from './Sections/ImageSection';
import { Col, Row } from 'react-bootstrap';
import ListWithNumbers from './Sections/ListWithNumber';
import { useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

const ContactPage = () => {
    const ref = useRef(null);
    const [heightOfBox, setHeightOfBox] = useState(0);
    const [mapAnimation, setMapAnimation] = useState({ opacity: 0 });
    const [loadingMapAnimation, setLoadingMapAnimation] = useState(true);

    useLayoutEffect(() => {
        setHeightOfBox(ref?.current?.clientHeight);
    }, []);

    const onLoadMap = () => {
        setLoadingMapAnimation(false);
        setMapAnimation({ opacity: 0, animation: "simplyOpacity 1s 0.3s forwards" });
    }

    const contactData = [
        [`mgr Anna Ludera`, `698 879 571`],
        [`mgr Jadwiga Jochym-Stachyra`, `696 003 071`],
        ['mgr Anna Dziobak', `607 035 117`]
    ]
    const generateContactTable = () => {
        return <table className="contactTable">
            <tbody>
                {contactData.map((contact, index) => <tr key={`contact_${index}`}><td className="name_column">
                    <Link className="link" to={{
                        pathname: "/about",
                        state: {
                            personnelId: index
                        }
                    }}>{contact[0]}</Link>
                </td><td className="phoneNumber_column">{contact[1]}</td></tr>)}
            </tbody>
        </table>
    }

    return (
        <div>
            <div id="offer-page">
                <ImageSection
                    height="25vh"
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    title={`Kontakt`}
                />
                <div className="box" style={{ padding: "25px 50px" }} ref={ref}>
                    <div>
                        <div className="contact_text_container" >
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="addressCol">
                                        {`Gabinet diagnozy psychologicznej i psychoterapii mieści się w:

                            Spółdzielnia RENA
                            Al. Rejtana 10
                            35-310 Rzeszów
                            III piętro, pok. 320
                            `}
                                    </div>
                                    <div className="contactCol" >
                                        <div>{`Rejestracja do poszczególnych psychologów-psychoterapeutów:`}</div>

                                        {generateContactTable()}

                                        <div>{`Wizyty tylko po wcześniejszym umówieniu spotkania. `}</div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className={loadingMapAnimation ? "isLoading" : ""}>
                                        <iframe onLoad={onLoadMap} style={mapAnimation} height="400px" width="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2562.7282765635887!2d22.0158463!3d50.035187199999996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfafbcf930743%3A0xef4188382e71c8c2!2sAleja+Tadeusza+Rejtana+10!5e0!3m2!1spl!2spl!4v1396907856087" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>


                </div>
                <ImageSection
                    height={`calc(75vh - 71px - ${heightOfBox}px)`}
                    image={image}
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    imagePosition="0% 30%"
                />




            </div>
        </div>
    );
};

export default ContactPage;
