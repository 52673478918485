import React from 'react';
import { Link } from 'react-router-dom';
import ImageSection from './Sections/ImageSection';
import image from "../assets/images/kid.jpg";

const NotFoundPage = () => {
  return (
    <div id="offer-page">
      <ImageSection
        height="calc(100vh - 20px)"
        image={image}
        imagePosition="0 30%"
        title={`404 Strona nie została znaleziona`}
        titleVerticalAlign="center"
        description={<Link to="/" className="link">Powrót do strony głównej</Link>}
        withGradient
        gradientPosition="bottom"
      />
    </div>
  );
};

export default NotFoundPage;
