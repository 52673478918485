import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";

const propTypes = { sticky: PropTypes.bool };
const defaultProps = { sticky: false };

function Header({ sticky }) {
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [clickedItem, setClickedItem] = useState(null);
    
    const ref = useRef(null);
    const location = useLocation();

    //#region UpdateMethods
    useEffect(() => {
        if (sticky) window.addEventListener('scroll', handleOnScroll);
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            window.removeEventListener('scroll', handleOnScroll);
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, []);

    useEffect(() => {
        onToggleMenu(false);
    }, [location]);

    useEffect(() => {
        showMenu ? document.body.classList.add("menu_open") : document.body.classList.remove("menu_open");
    }, [showMenu]);

    useEffect(() => {
        if (window.scrollY > 40)
            setShowStickyHeader(true);
        else if (!showMenu) setShowStickyHeader(false);
    }, [scrollY]);

    useEffect(() => {
        if (showMenu && ref.current && !ref.current.contains(clickedItem)) {
            onToggleMenu();
        }
    },[clickedItem]);
    //#endregion

    const handleOnScroll = (obj) => {
        if (sticky) {
            setScrollY(window.scrollY);
        }
    }
    const handleClickOutside = (event) => {
        setClickedItem(event.target);
    };

    const onToggleMenu = (forceState = undefined) => {
        if (forceState != undefined) {
            setShowMenu(forceState);
        }
        else {
            setShowMenu(!showMenu);
        }
    }


    return (
        <div id="header" className={`header${sticky ? "__sticky" : ""} ${showStickyHeader ? "showStickyHeader" : ""}`}>
            <div className="header-body" ref={ref}>
                <div className="header__logo">SENSUM</div>
                <nav className={`${showMenu ? "show" : ""}`} >
                    <NavLink exact to="/" >Home</NavLink>
                    <NavLink to="/about" >Kadra</NavLink>
                    <NavLink to="/offer" >Oferta/Cennik</NavLink>
                    <NavLink to="/workmethodology" >Metoda Pracy</NavLink>
                    <NavLink to="/contact" >Kontakt</NavLink>
                </nav>
                <div className="hamburger_menu" onClick={() => { onToggleMenu() }}><span /><span /><span /></div>
            </div>
        </div>
    )
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default memo(Header);