import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
    items: PropTypes.array,
    style: PropTypes.object,
};
const defaultProps = {
    items: [],
    style: {}
};

const ListWithNumbers = ({
    items,
    style
}) => {

    const bootstrapWidthCol = 12 / items.length;

    return (
        <div className="list_with_numbers" style={style}>
            <Row>
                {items.map((item, index) => {
                    const mdWidth = items.length == index+1 && items.length%2 != 0 ? 12 : 6
                    return (

                        <Col xs={12} md={mdWidth} lg={bootstrapWidthCol} className='list_with_numbers__item'>
                            <ScrollAnimation animatePreScroll={false}
                                animateIn="fadeIn"
                                offset={200 + (index * 120)}
                                duration={0.8}
                                animateOnce
                                animatePreScroll={index == 0}
                            >
                                <div className='list_with_numbers__item_number'>
                                    {index + 1}
                                </div>
                                <div className="list_with_numbers__item_text">
                                    {item}
                                </div>
                            </ScrollAnimation>
                        </Col>)
                }
                )}
            </Row>
        </div >
    );
};

ListWithNumbers.propTypes = propTypes;
ListWithNumbers.defaultProps = defaultProps;

export default ListWithNumbers;
