import * as React from "react";

function TeensIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.35 272.35" fill="#fff" {...props}>
            <g id="Warstwa_2" data-name="Warstwa 2">
                <g id="teens">
                    <path class="cls-1" d="M136.17,272.35A136.18,136.18,0,1,0,0,136.17,136.18,136.18,0,0,0,136.17,272.35Zm.07-114.84s13-34.67,17.09-44.44,3.74-10.36,11.07-10.36h8.3s1.21-7.39.27-7.65C164,92.57,159,83.8,159,73.47c0-12.27,7.71-22.15,19.17-22.15l1.79,0h.49c1.32,0,5.13,1.58,7.24,1.28,1.47-10.19,18-10.29,19.82.31s-1.67,8.79-1.57,17.64c.08,7.19,2.56,9.82,1.59,12-.67.91-9-11.6-11.07-10.88-.35.62-.1,1.34-.1,2,0,10.34-2.25,18.9-11.18,21.38-.95.26.27,7.65.27,7.65h8.3c7.32,0,7,.6,11.07,10.37s17.08,44.43,17.08,44.43,2.17,5.46-2.35,7-6.88-5.22-6.88-5.22-13.67-36-15.38-37c-.42-.25-.5,3.89-.5,3.89l.23,106.7s-.22,4-6.69,4-6.59-4-6.59-4l-.24-67h-8.87l-.25,67s-.12,4-6.59,4-6.68-4-6.68-4l.22-106.7s-.07-4.14-.49-3.9c-1.71,1-15.39,37-15.39,37s-2.36,6.8-6.88,5.22S136.24,157.51,136.24,157.51Zm-93.63.25s13-34.68,17.09-44.44S63.44,103,70.77,103h8.3s1.22-7.4.27-7.66c-8.93-2.48-15.53-11.17-15.53-21.51,0-12.27,9.29-22.21,20.75-22.21l1.79,0h.49c3.21.08,10.52-2,13.2-.51.86.47-4.88,2.83-1.81,4.51,5.36,4,8.06,10.93,8.06,18.46,0,10.34-5.79,18.78-14.72,21.26-.95.27.27,7.66.27,7.66h8.3c7.33,0,7,.61,11.07,10.37s17.09,44.44,17.09,44.44,2.16,5.46-2.36,7-6.88-5.21-6.88-5.21-13.67-36-15.38-37c-.42-.24-.49,3.89-.49,3.89l.22,106.71s-.22,4-6.69,4-6.59-4-6.59-4l-.24-67H81l-.25,67s-.11,4-6.58,4-6.69-4-6.69-4l.22-106.7s-.07-4.13-.49-3.89c-1.71,1-15.38,37-15.38,37s-2.37,6.8-6.88,5.21S42.61,157.76,42.61,157.76Z" />
                </g>
            </g>
        </svg>
    );
}
export default TeensIcon;