import * as React from "react";

function AdultsIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.35 272.23" fill="#fff" {...props}>
            <g id="Warstwa_2" data-name="Warstwa 2">
                <g id="Warstwa_5" data-name="Warstwa 5">
                    <path class="cls-1" d="M23.19,183.87c2.53-15.88,3.61-13,5-14.55s36.34-16.27,38.56-15,5,8.77,8.18,19.47c3.87,13.16,6.94,28.88,8,28.75,1.93-.24,5.17-44.09,4.63-45.9S70,146.94,70,146.94l.12-12.87-7-15.76s-3.11,0-3.76-4.87-1.41-8.76-.81-11.58,3.73-1.36,3.73-1.36.12-16.12,2.77-23.21S73,64.77,80,63.45a55,55,0,0,1,12.88-1.32c3.73,0,12.15,1,15.27,3.37s9.63,7.33,10,22.13.6,12.15.6,12.15,2.56-.6,3.37.6-.72,18.14-2.13,18.84-3.16,1.37-3.16,1.37-2.41,9.15-5.18,11.91v14.08S96,155.2,94.83,157.35s1.75,43.91,3,44.33.48-.42,2.28-.72c1-.16,2.53-13.16,5.78-25.57,3-11.3,7.62-22.11,8.42-22.31,1.68-.43,21.53,10,21.53,10s-15.51,12-16.72,25.93c-1.06,12.35,2,69.58,2.74,82.54q4.39.46,8.85.64c-.45-11.88-2.81-73.34-2.81-79.69,0-7,4.15-15.7,10.29-20s21.65-8.3,21.65-8.3,8.85,14.8,17.33,17.51,19.31,2.61,26.43-.72,16.88-15.34,16.88-15.34,11.36.9,19.49,5,13,14.44,13,20c0,1.44-.12,7.65-.3,16.09a136.18,136.18,0,1,0-231.6,2C21.71,197.8,22.47,188.43,23.19,183.87Zm132.45-58.64c3.07-5.78,4.52-20.94,5.78-33.65s7-18.14,11.73-21.39,24.54-7.22,35.19,0,12.45,21.11,13.71,40.06S229.45,131,236.49,137c-13.54-.18-31.22,5.77-31.22,5.77s0,7.76.18,10.83,6.5,6.13,9.2,9C204,175.75,194.08,177.2,194.08,177.2s-1.62.54-10.29,0S165,162.58,165,162.58c13.17-4,10.64-17.14,11-19.85-7-5-32.54-6-32.85-5.95C142.16,137.1,152.58,131,155.64,125.23Z" />
                </g>
            </g>
        </svg>
    );
}
export default AdultsIcon;