import React, { useEffect, useRef, useState } from 'react';
import image from '../assets/images/kid.jpg';
import ImageSection from './Sections/ImageSection';
import aboutData from "../assets/aboutData";
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
const AboutPage = () => {

  const ref = useRef(null);
  const [heightOfBox, setHeightOfBox] = useState(1000);
  const [selectedPerson, setSelectedPerson] = useState(-1);
  const location = useLocation();

  const getPersonDescription = () => {
    switch (selectedPerson) {
      case 0: return aboutData.AnnaLuderaDescription;
      case 1: return aboutData.JadwigaJochymStachyraDescription;
      case 2: return aboutData.AnnaDziobakDescription;
    }
  }
  useEffect(() => {
    console.log(location.state);
    if (location.state && location.state.personnelId != undefined)
      setSelectedPerson(location.state.personnelId);

    setHeightOfBox(ref?.current?.clientHeight);
  }, []);
  useEffect(() => {
    setHeightOfBox(ref?.current?.clientHeight);
  }, [selectedPerson]);

  const heightOfFirstImage = selectedPerson == -1 ? 40 : 20;
  
  const selectIfAnyNotActive = (index) => {
    document.activeElement.blur();
    return selectedPerson == index ? -1 : index
  }

  const onPersonChange = (event, index) => {
    setSelectedPerson(selectIfAnyNotActive(index));
  }


  return (
    <div>
      <div id="about-page">
        <ImageSection
          height={`${heightOfFirstImage}vh`}
          withGradient
          gradientPosition="bottom"
          gradientHeight="30%"
          title="Kadra"
        />
      </div>

      <div className="box" ref={ref}>
        <div>
          <div className="about_text_container">
            <Row className={`about__persons_row ${selectedPerson == -1 ? "noneSelected" : ""}`}>
              <Col xs={12} md={4} className={`about__person about__person_0 fade-in ${selectedPerson == 0 ? "active" : ""}`} onClick={(event) => onPersonChange(event, 0)}>
                <div className="fade-in" >
                  <i className="fa fa-arrow-left" onClick={() => setSelectedPerson(-1)} />
                  <div className={`personPosition`}>{aboutData.PersonPosition}</div>
                  <div className="personName"><span className="degrees">{`mgr`}</span>{aboutData.AnnaLuderaName}</div>
                </div>
              </Col>
              <Col xs={12} md={4} className={`about__person about__person_1 ${selectedPerson == 1 ? "active" : ""}`} onClick={(event) => onPersonChange(event, 1)}>
                <div className="fade-in" style={{ animationDelay: "0.2s" }}>
                  <i className="fa fa-arrow-left" onClick={() => setSelectedPerson(-1)} />
                  <div className={`personPosition`}>{aboutData.PersonPosition}</div>
                  <div className="personName"><span className="degrees">{`mgr`}</span>{aboutData.JadwigaJochymStachyraName}</div>
                </div>
              </Col>
              <Col xs={12} md={4} className={`about__person about__person_2 ${selectedPerson == 2 ? "active" : ""}`} onClick={(event) => onPersonChange(event, 2)}>
                <div className="fade-in" style={{ animationDelay: "0.4s" }}>
                  <i className="fa fa-arrow-left" onClick={() => setSelectedPerson(-1)} />

                  <div className={`personPosition`}>{aboutData.PersonPosition}</div>
                  <div className="personName"><span className="degrees">{`mgr`}</span>{aboutData.AnnaDziobakName}</div>
                </div>
              </Col>
            </Row>
          </div>

          {selectedPerson != -1 && (
            <div style={{ color: "#fff", marginTop: "50px", whiteSpace: "pre-line" }}>
              {getPersonDescription()}
            </div>
          )}

          {/* <ImageCardsSection items={[
          { image: personImage1, position: aboutData.PersonPosition, name: aboutData.AnnaLuderaName, description: aboutData.AnnaLuderaDescription },
          { image: personImage2, position: aboutData.PersonPosition, name: aboutData.JadwigaJochymStachyraName, description: aboutData.JadwigaJochymStachyraDescription },
          { image: personImage3, position: aboutData.PersonPosition, name: aboutData.AnnaDziobakName, description: aboutData.AnnaDziobakDescription }
        ]} /> */}
        </div>

      </div>
      <ImageSection
        height={`calc(${100 - heightOfFirstImage}vh - 69px - ${heightOfBox}px)`}
        image={image}
        withGradient
        gradientPosition="bottom"
        gradientHeight="30%"
        imagePosition="0 30%"
        animated={false}
      />
    </div>
  );
};

export default AboutPage;
