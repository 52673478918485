import React, { useRef, useState } from 'react';
import personImage3 from '../assets/images/tree.jpg';
import personImage1 from '../assets/images/woman_face.jpg';
import personImage2 from '../assets/images/woman.jpg';
import image2 from '../assets/images/mother_with_baby.jpg';
import image from '../assets/images/kid.jpg';
import ImageCardsSection from './Sections/ImageCardsSection';
import ImageSection from './Sections/ImageSection';
import offerData from "../assets/offerData";
import { Col, Row } from 'react-bootstrap';
import ListWithNumbers from './Sections/ListWithNumber';
import ScrollAnimation from 'react-animate-on-scroll';
import { useEffect } from 'react';


import ChildrenIcon from '../assets/images/icons/ChildrenIcon';
import TeensIcon from '../assets/images/icons/TeensIcon';
import AdultsIcon from '../assets/images/icons/AdultsIcon';

const OfferPage = () => {
    const ref = useRef(null);
    const pricesRef = useRef(null);

    const svgProps = { className: "offerIcon", width: "10rem", height: "10rem" }
    const offerItems = [
        { icon: <ChildrenIcon {...svgProps} />, title: 'Psychoterapia dzieci' },
        { icon: <TeensIcon {...svgProps} />, title: 'Psychoterapia młodzieży ' },
        { icon: <AdultsIcon {...svgProps} />, title: 'Psychoterapia dorosłych' }];

    const [activeOfferQueue, setActiveOfferQueue] = useState([]);
    const [activeOffer, setActiveOffer] = useState(-1);
    const [heightOfBox, setHeightOfBox] = useState(1000);
    const [heightOfPricesBox, setHeightOfPricesBox] = useState(1000);

    useEffect(() => {
        setHeightOfBox(ref?.current?.clientHeight);
        setHeightOfPricesBox(pricesRef?.current?.clientHeight);
    }, []);
    useEffect(() => {
        if (activeOfferQueue.length > 1) {
            setActiveOffer(activeOfferQueue[0]);
            setActiveOfferQueue([activeOfferQueue[1]]);
        }
        else if (activeOfferQueue.length == 1) {
            setActiveOffer(activeOfferQueue[0]);
            setActiveOfferQueue([]);
        }
    }, [activeOfferQueue]);

    //#region Offer Data

    const psychotherapyOfChildren = (
        <div className="offer_text_container" style={{ animation: "simplyOpacity 1s forwards" }}>

            <span className="section-title">{`Psychoterapia dzieci `}</span>
            <span>
                {`Rozpoczęcie psychoterapii poprzedzone jest konsultacjami psychologicznymi (kilka spotkań). Prowadzimy je dwutorowo: spotkania odbywają się zarówno z rodzicem/rodzicami oraz osobno z dzieckiem (z wyjątkiem bardzo małych dzieci, które wymagają towarzyszenia rodzica).


Konsultacja dziecka przebiega w trzech etapach:

`}
                <ListWithNumbers
                    style={{ marginTop: "15px", marginBottom: "0px" }}
                    items={[
                        `Pierwsza wizyta odbywa się z rodzicami, w celu szczegółowego omówienia trudności dziecka, zebrania tzw. wywiadu psychologicznego.`,
                        `Drugi etap stanowią spotkania z dzieckiem (zwykle około trzech), które przeznaczamy na nawiązanie kontaktu z dzieckiem, zabawę, obserwację i rozmowę z małym pacjentem oraz na dokonanie diagnozy psychologicznej.`,
                        `Trzeci etap to ponowna rozmowa z rodzicami - przedstawiamy wyniki badań, obserwacje, nasze rozumienie problemu i wspólnie je omawiamy. Często takie wstępne rozpoznanie sytuacji dziecka okazuje się pomocne i nie wymaga podejmowania dalszych kroków. Jeśli jednak jest taka potrzeba proponujemy i omawiamy dostępne formy pomocy. Może to być np. dokładniejsza diagnoza, poradnictwo dla rodziców lub psychoterapia indywidualna dziecka, a także konsultacja z innym specjalistą.`
                    ]} />
            </span>
        </div>
    );

    const psychotherapyOfTeens = (
        <div className="offer_text_container" style={{ animation: "simplyOpacity 1s forwards" }}>

            <span className="section-title">{`Psychoterapia młodzieży `}</span>
            <span>
                {`Na pierwsze spotkanie zapraszamy rodzica/rodziców, aby dowiedzieć się jakie dostrzega trudności w nastolatku, co go niepokoi. 

                Po zebraniu szczegółowego wywiadu psychologicznego od rodzica umawiamy się na kilka spotkań (konsultacje psychologiczne) z nastolatkiem. 
                W ich trakcie poznajemy adolescenta oraz wspólnie decydujemy czy podejmuje psychoterapię. 
                
                Długość trwania terapii jest różna, zależy m.in. od rodzaju problemów nastolatka, jego motywacji. Spotkania z rodzicami są wymagane w przypadku osób niepełnoletnich. Rodzice muszą wyrazić zgodę na diagnozę i ewentualną terapię nastolatka.`}
            </span>
        </div>
    )

    const psychotherapyOfAdults = (
        <div className='offer_text_container' style={{ animation: "simplyOpacity 1s forwards" }}>
            <span className="section-title">{`Psychoterapia dorosłych`}</span>
            <span>
                {`Rozpoczęcie psychoterapii poprzedza kilka (zwykle 3-4) konsultacji psychologicznych. 
                Mają one na celu rozpoznanie trudności pacjenta i zaoferowanie mu odpowiedniej formy pomocy. 
                Sesje psychoterapii odbywają się w określone dni i godziny, minimum 1 raz w tygodniu. 
                
                Czas trwania psychoterapii zależy od rodzaju trudności, z którymi zmaga się pacjent. Każdy proces terapeutyczny jest zindywidualizowany, wygląda inaczej, gdyż jest dostosowywany do problematyki danego pacjenta.`}
            </span>
        </div>
    )
    //#endregion

    const getActiveContent = () => {
        let content = null;
        switch (activeOffer) {
            case 0:
                content = psychotherapyOfChildren;
                break;
            case 1:
                content = psychotherapyOfTeens;
                break;
            case 2:
                content = psychotherapyOfAdults;
                break;
            default:
        }
        return (
            <div className='box' style={{ padding: `${content != null ? "25px 50px" : "0px"}` }}>{content}
            </div>);
    }

    const onItemClick = (index) => {
        const calcIndex = activeOffer == index ? -1 : index;
        if (calcIndex != -1) setActiveOfferQueue([-1, calcIndex])
        else setActiveOffer(-1);
    }

    const heightOfFirstImageSection = 30;

    return (
        <div>
            <div id="offer-page">
                <ImageSection
                    height={`${activeOffer != -1 ? "20vh" : heightOfFirstImageSection + "vh"}`}
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    title={`Oferta/Cennik`}
                />
                <div className="box" style={{ padding: "25px 50px" }} ref={ref}>
                    <div>
                        {/* <span className="section-title">{`Oferta`}</span> */}
                        <div className={`offerIconList`}>

                            {offerItems.map((item, index) => {
                                let icon = "";
                                if (typeof (item.icon) === "string")
                                    icon = <i className={`fa fa-${item.icon}`} />
                                else
                                    icon = item.icon;

                                return (
                                    <div key={`offerItem_${index}`} className={`item_${index} fade-in`} style={{ animationDelay: `${0.2 * index}s` }}>
                                        <div className={`offerIconList_item ${activeOffer == index ? "active" : ""}`}
                                            onClick={() => { onItemClick(index) }}>
                                            <div className="offerIconList_item__icon">
                                                {icon}
                                            </div>
                                            <div className="offerIconList_item__title">{item.title}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <ImageSection
                    height={`${activeOffer != -1 ? "10vh" : `calc(${100 - heightOfFirstImageSection}vh  - 69px - ${heightOfBox}px - ${heightOfPricesBox}px)`}`}
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                />
                {getActiveContent()}
                <ImageSection
                    height={`${activeOffer != -1 ? "10vh" : `0vh`}`}
                    image={image}
                    withGradient
                    gradientPosition="bottom"
                    gradientHeight="30%"
                    imagePosition="0 30%"
                    animated={activeOffer != -1}
                />
                <div className="box" style={{ padding: "25px 50px" }} ref={pricesRef}>
                    <div className="offer_text_container">
                        <div className="section-title">{`Cennik`}</div>
                        <div style={{ color: "#fff", whiteSpace: "pre-line", marginBottom: "30px" }}>
                            {`Konsultacje  (50 min)       150 zł
                        Honorarium za sesje terapeutyczne ustalane jest podczas konsultacji.
`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferPage;
